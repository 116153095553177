// Libraries
import React, {PureComponent} from 'react'

// Types
import {ConnectorState} from 'src/reusable_ui/constants/wizard'

import {ErrorHandling} from 'src/shared/decorators/errors'

interface Props {
  status: ConnectorState
}

class ProgressConnector extends PureComponent<Props> {
  public render() {
    const {status} = this.props

    return (
      <span
        className={`wizard-progress-connector wizard-progress-connector--${
          status || ConnectorState.None
        }`}
      />
    )
  }
}

export default ErrorHandling(ProgressConnector)
