(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(['exports', 'bignumber.js', 'core-decorators', 'memoizerific'], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require('bignumber.js'), require('core-decorators'), require('memoizerific'));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.bignumber, global.coreDecorators, global.memoizerific);
    global.index = mod.exports;
  }
})(this, function (exports, _bignumber, _coreDecorators, _memoizerific) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _bignumber2 = _interopRequireDefault(_bignumber);

  var _memoizerific2 = _interopRequireDefault(_memoizerific);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _class, _desc, _value, _class2;

  // Cache original 'Date' class. User may set window.Date = NanoDate;
  if (typeof window === 'undefined') {
    /* istanbul ignore next */
    var BaseDate = Date;
  } else {
    var BaseDate = window.Date;
  }

  var ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

  var MILLI_TO_NANO_DIFF = 1000000;
  var DIGETS_IN_NANO = 19;
  var DIGETS_IN_MILLI = 13;
  var MINIMUM_DIGETS = DIGETS_IN_NANO - DIGETS_IN_MILLI;

  var YEAR = 'year';
  var MONTH = 'month';
  var DAY = 'day';
  var HOUR = 'hour';
  var MINUTE = 'minute';
  var SECOND = 'second';
  var MILLI = 'milli';
  var MICRO = 'micro';
  var NANO = 'nano';

  var DAYS = {
    0: 31,
    1: 28,
    2: 31,
    3: 30,
    4: 31,
    5: 30,
    6: 31,
    7: 31,
    8: 30,
    9: 31,
    10: 30,
    11: 31
  };

  var DAYS_LEAP_YEAR = {
    0: 31,
    1: 29,
    2: 31,
    3: 30,
    4: 31,
    5: 30,
    6: 31,
    7: 31,
    8: 30,
    9: 31,
    10: 30,
    11: 31
  };

  function pad(num) {
    return addZeros(num, num < 100 ? num < 10 ? 2 : 1 : 0, true);
  }

  function padEndTo(num, toLen) {
    return addZeros(num, 9 - ('' + num).length);
  }

  function addZeros(str) {
    var count = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var front = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    if (count === 0) {
      return str;
    }
    return addZeros('' + (front ? '0' : '') + str + (!front ? '0' : ''), count - 1, front);
  }

  function toNano(num) {
    var str = '' + num;
    if (str.length <= DIGETS_IN_NANO) {
      if (str.indexOf('.') > -1) {
        str = num.toFixed(3);
        return new _bignumber2.default(str.replace('.', '') + '000');
      }
      return new _bignumber2.default(str);
    }
    return new _bignumber2.default(str.slice(0, DIGETS_IN_NANO));
  }

  function onlyDigits(num) {
    return (/^(-)?\d+$/.test('' + num)
    );
  }

  function leapYear(yearBase) {
    var year = yearBase.toNumber();
    return year % 4 === 0 && year % 100 !== 0 || year % 400 === 0;
  }

  function daysForYear(year) {
    return leapYear(year) ? 366 : 365;
  }

  function daysForMonth(year, month) {
    var days = leapYear(year) ? DAYS_LEAP_YEAR : DAYS;
    return days[month.toNumber()];
  }

  function handleNotAnInteger(items, funcName, names) {
    var index = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

    var item = items[index];
    var name = names[index];
    if (typeof item !== 'number' || !onlyDigits(item)) {
      throw new Error('Parameter ' + name + ' value for ' + funcName + ' has to be an integer.');
    } else if (names.length > index && notUndefined(items[index + 1])) {
      handleNotAnInteger(items, funcName, names, index + 1);
    }
  }

  function notUndefined(item) {
    return typeof item !== 'undefined';
  }

  function buildSetFunction(scope, name, argumentNames, getMethod, valueKey) {
    var moreVarsFunc = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : function () {};
    var utc = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;

    function setFunction() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      handleNotAnInteger(args, name, argumentNames);
      var currentValue = getMethod();
      if (currentValue !== args[0]) {
        if (currentValue < args[0]) {
          var v = scope._getValue.call(scope, scope, valueKey, args[0] - currentValue, utc);
          scope._full = scope._full.plus(v).truncated();
        } else {
          var _v = scope._getValue.call(scope, scope, valueKey, currentValue - args[0], utc);
          scope._full = scope._full.minus(_v).truncated();
        }

        if (args.length === 1 || args.length > 1 && !notUndefined(args[1])) {
          scope._setupFunctions.call(scope);
        }
      }

      if (args.length > 1 && notUndefined(args[1])) {
        args.shift();
        moreVarsFunc.apply(scope, args);
      }

      return getMethod();
    }

    Object.defineProperty(setFunction, 'name', { value: name, writable: false });

    return setFunction.bind(scope);
  }

  var passThroughMethods = ['getFullYear', 'getYear', 'getMonth', 'getDate', 'getDay', 'getHours', 'getMinutes', 'getSeconds', 'getMilliseconds', 'getUTCFullYear', 'getUTCYear', 'getUTCMonth', 'getUTCDate', 'getUTCDay', 'getUTCHours', 'getUTCMinutes', 'getUTCSeconds', 'getUTCMilliseconds', 'toDateString', 'toLocaleDateString', 'toLocaleString', 'toTimeString', 'toLocaleTimeString', 'toISOString', 'getTimezoneOffset'];

  var NanoDate = (_dec = (0, _coreDecorators.decorate)((0, _memoizerific2.default)(250)), _dec2 = (0, _coreDecorators.decorate)((0, _memoizerific2.default)(100)), _dec3 = (0, _coreDecorators.deprecate)('Use toUTCString() instead'), (0, _coreDecorators.autobind)(_class = (_class2 = function () {
    function NanoDate(a, b, c, d, e, f, g, h) {
      _classCallCheck(this, NanoDate);

      if (typeof a === 'string') {
        this._full = onlyDigits(a) ? toNano(a) : toNano(new BaseDate(a).valueOf() * MILLI_TO_NANO_DIFF);
      } else if (arguments.length === 0) {
        this._full = toNano(new BaseDate().valueOf() * MILLI_TO_NANO_DIFF);
      } else if (arguments.length === 1) {
        if (a instanceof NanoDate) {
          this._full = a._full;
        } else if (a instanceof BaseDate) {
          this._full = toNano(a.valueOf() * MILLI_TO_NANO_DIFF);
        } else if (typeof a === 'number') {
          var multi = MILLI_TO_NANO_DIFF;
          if (('' + a).indexOf('.') > -1) {
            multi = 1;
          }
          this._full = toNano(a * multi);
        } else {
          throw Error('Input not of any type that can be converted to a date');
        }
      } else {
        var date = void 0;
        if (typeof a === 'boolean') {
          date = BaseDate.UTC(b, c || 0, d || 0, e || 0, f || 0, g || 0, h || 0);
        } else {
          date = new BaseDate(a, b, c || 0, d || 0, e || 0, f || 0, g || 0);
        }
        this._full = toNano(date.valueOf() * MILLI_TO_NANO_DIFF);
      }

      this._setupFunctions();

      if (typeof a === 'string' && ISO_8601_FULL.test(a) && a.indexOf('.') > -1) {
        var match = a.match(ISO_8601_FULL);
        if (typeof match[1] !== 'undefined') {
          var padded = padEndTo(match[1].replace('.', ''), 9);
          var nanos = parseInt(padded, 10);

          // set milliseconds
          if (nanos > 0) {
            this.setMilliseconds(Math.floor(nanos / MILLI_TO_NANO_DIFF));
            nanos = nanos % MILLI_TO_NANO_DIFF;
          }

          // set microseconds
          if (nanos > 0) {
            this.setMicroseconds(Math.floor(nanos / 1000));
            nanos = nanos % 1000;
          }

          // set nanoseconds
          if (nanos > 0) {
            this.setNanoseconds(Math.floor(nanos));
          }
        }
      }
    }

    _createClass(NanoDate, [{
      key: '_setupFunctions',
      value: function _setupFunctions() {
        var _this = this;

        this._date = new BaseDate(this.valueOf());
        passThroughMethods.forEach(function (name) {
          _this[name] = function () {
            var _date;

            return (_date = _this._date)[name].apply(_date, arguments);
          };
        });

        this._buildSetFunctions();
      }
    }, {
      key: '_getDaysBetween',
      value: function _getDaysBetween(a, b, func) {
        if (a.eq(b)) {
          return new _bignumber2.default(0);
        }
        var days = new _bignumber2.default(0);
        var diff = a.lt(b) ? 1 : -1;
        var start = new _bignumber2.default(a);
        while (!start.eq(b)) {
          var val = func(start);
          days = days.plus(val);
          start = start.plus(diff);
        }
        return days.times(diff);
      }
    }, {
      key: '_getFullYear',
      value: function _getFullYear(utc) {
        return utc ? this.getUTCFullYear() : this.getFullYear();
      }
    }, {
      key: '_getDate',
      value: function _getDate(utc) {
        return utc ? this.getUTCDate() : this.getDate();
      }
    }, {
      key: '_getMonth',
      value: function _getMonth(utc) {
        return utc ? this.getUTCMonth() : this.getMonth();
      }
    }, {
      key: '_getDays',
      value: function _getDays(unit) {
        var utc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var currentYear = new _bignumber2.default(this._getFullYear(utc));
        var base = new _bignumber2.default(unit);
        var res = new _bignumber2.default(this._getDate(utc)).minus(1);
        if (unit >= 12) {
          var years = base.dividedToIntegerBy(12);
          var start = new _bignumber2.default(currentYear);
          var end = start.plus(years);
          res = this._getDaysBetween(start, end, daysForYear);
          base = base.plus(years.times(12).times(base.lessThan(0) ? 1 : -1));
        }

        var month = new _bignumber2.default(this._getMonth(utc));
        var diff = month.plus(base);
        if (diff.greaterThan(11)) {
          diff = month.minus(base);
        }
        // console.log('days between', res.toNumber(), diff.toNumber(), month.toNumber());
        if (diff.lessThan(month)) {
          return res.plus(this._getDaysBetween(diff, month, daysForMonth.bind(null, currentYear)));
        }
        return res.plus(this._getDaysBetween(month, diff, daysForMonth.bind(null, currentYear)));
      }
    }, {
      key: '_getValue',
      value: function _getValue(scope, type, unit) {
        var utc = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

        var numUnit = new _bignumber2.default(unit);
        switch (type) {
          case YEAR:
            return scope._getValue(scope, MONTH, numUnit.times(12), utc);
          case MONTH:
            return scope._getValue(scope, DAY, scope._getDays(numUnit, utc), utc);
          case DAY:
            return scope._getValue(scope, HOUR, numUnit.times(24), utc);
          case HOUR:
            return scope._getValue(scope, MINUTE, numUnit.times(60), utc);
          case MINUTE:
            return scope._getValue(scope, SECOND, numUnit.times(60), utc);
          case SECOND:
            return scope._getValue(scope, MILLI, numUnit.times(1000), utc);
          case MILLI:
            return scope._getValue(scope, MICRO, numUnit.times(1000), utc);
          case MICRO:
            return scope._getValue(scope, NANO, numUnit.times(1000), utc);
          case NANO:
          default:
            return numUnit;
        }
      }
    }, {
      key: 'getTime',
      value: function getTime() {
        return this._full.toString();
      }
    }, {
      key: 'valueOf',
      value: function valueOf() {
        return this._full.dividedBy(MILLI_TO_NANO_DIFF).truncated().toNumber();
      }
    }, {
      key: 'valueOfWithMicro',
      value: function valueOfWithMicro() {
        return parseFloat(this.valueOf() + '.' + pad(this.getMicroseconds()), 10);
      }
    }, {
      key: 'valueOfWithNano',
      value: function valueOfWithNano() {
        return '' + this.valueOfWithMicro().toFixed(3) + pad(this.getNanoseconds());
      }
    }, {
      key: 'getMicroseconds',
      value: function getMicroseconds() {
        return this._full.minus(this.valueOf() * MILLI_TO_NANO_DIFF).dividedBy(1000).truncated().toNumber();
      }
    }, {
      key: 'getUTCMicroseconds',
      value: function getUTCMicroseconds() {
        return this.getMicroseconds();
      }
    }, {
      key: 'getNanoseconds',
      value: function getNanoseconds() {
        return this._full.minus(this.valueOf() * MILLI_TO_NANO_DIFF).minus(this.getMicroseconds() * 1000).truncated().toNumber();
      }
    }, {
      key: 'getUTCNanoseconds',
      value: function getUTCNanoseconds() {
        return this.getNanoseconds();
      }
    }, {
      key: '_buildSetFunctions',
      value: function _buildSetFunctions() {
        var build = buildSetFunction.bind(this, this);

        this.setUTCNanoseconds = this.setNanoseconds = build('setNanoseconds', ['nanosecond'], this.getUTCNanoseconds, NANO);

        this.setUTCMicroseconds = this.setMicroseconds = build('setMicoseconds', ['microsecond', 'nanosecond'], this.getUTCMicroseconds, MICRO, this.setUTCNanoseconds);

        this.setUTCMilliseconds = this.setMilliseconds = build('setMilliseconds', ['millisecond', 'microsecond', 'nanosecond'], this.getUTCMilliseconds, MILLI, this.setUTCMicroseconds);

        this.setUTCSeconds = build('setUTCSeconds', ['second', 'millisecond', 'microsecond', 'nanosecond'], this.getUTCSeconds, SECOND, this.setUTCMilliseconds, true);

        this.setSeconds = build('setSeconds', ['second', 'millisecond', 'microsecond', 'nanosecond'], this.getSeconds, SECOND, this.setMilliseconds);

        this.setUTCMinutes = build('setUTCMinutes', ['minute', 'second', 'millisecond', 'microsecond', 'nanosecond'], this.getUTCMinutes, MINUTE, this.setUTCSeconds, true);

        this.setMinutes = build('setMinutes', ['minute', 'second', 'millisecond', 'microsecond', 'nanosecond'], this.getMinutes, MINUTE, this.setSeconds);

        this.setUTCHours = build('setUTCHours', ['hour', 'minute', 'second', 'millisecond', 'microsecond', 'nanosecond'], this.getUTCHours, HOUR, this.setUTCMinutes, true);

        this.setHours = build('setHours', ['hour', 'minute', 'second', 'millisecond', 'microsecond', 'nanosecond'], this.getHours, HOUR, this.setMinutes);

        this.setUTCDate = build('setUTCDate', ['day'], this.getUTCDate, DAY, function () {}, true);

        this.setDate = build('setDate', ['day'], this.getDate, DAY);

        this.setUTCMonth = build('setUTCMonth', ['month', 'day'], this.getUTCMonth, MONTH, this.setUTCDate, true);

        this.setMonth = build('setMonth', ['month', 'day'], this.getMonth, MONTH, this.setDate);

        this.setUTCFullYear = build('setUTCFullYear', ['year', 'month', 'day'], this.getUTCFullYear, YEAR, this.setUTCMonth, true);

        this.setFullYear = build('setFullYear', ['year', 'month', 'day'], this.getFullYear, YEAR, this.setMonth);
      }
    }, {
      key: 'setTime',
      value: function setTime(time) {
        this._full = toNano(time);
        this._setupFunctions();
        return time;
      }
    }, {
      key: 'setUTCTime',
      value: function setUTCTime(time) {
        return this.setTime(time);
      }
    }, {
      key: '_toString',
      value: function _toString(funcName) {
        var split = this._date[funcName]().split(' GMT');
        var milli = this.getMilliseconds();
        var micro = this.getMicroseconds();
        var nano = this.getNanoseconds();
        split[0] += '.' + pad(milli) + pad(micro) + pad(nano);
        return split.join(' GMT');
      }
    }, {
      key: 'toString',
      value: function toString() {
        return this._toString('toString');
      }
    }, {
      key: 'toUTCString',
      value: function toUTCString() {
        return this._toString('toUTCString');
      }
    }, {
      key: 'toISOStringFull',
      value: function toISOStringFull() {
        var micro = this.getMicroseconds();
        var nano = this.getNanoseconds();
        return this._date.toISOString().replace('Z', '' + pad(micro) + pad(nano) + 'Z');
      }
    }, {
      key: 'toGMTString',
      value: function toGMTString() {
        return this.toUTCString();
      }
    }], [{
      key: 'now',
      value: function now() {
        return new NanoDate().valueOf();
      }
    }, {
      key: 'parse',
      value: function parse() {
        return BaseDate.parse.apply(BaseDate, arguments);
      }
    }, {
      key: 'UTC',
      value: function UTC() {
        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        return new (Function.prototype.bind.apply(NanoDate, [null].concat([true], args)))();
      }
    }]);

    return NanoDate;
  }(), (_applyDecoratedDescriptor(_class2.prototype, '_getDaysBetween', [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, '_getDaysBetween'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, '_getValue', [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, '_getValue'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'toGMTString', [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, 'toGMTString'), _class2.prototype)), _class2)) || _class);
  exports.default = NanoDate;
});