export const TASKS_TABLE = {
  colName: '200px',
  colTrigger: '90px',
  colMessage: '200px',
  colAlerts: '120px',
  colEnabled: '95px',
  colActions: '68px',
  colType: '90px',
  colEvery: '90px',
  colCron: '90px',
}
