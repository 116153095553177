'use strict'

exports.test = function (key) {
  return function test (impl) {
    try {
      impl.cmp(key, 0)
      return true
    } catch (err) {
      return false
    }
  }
}

exports.binaryKeys = exports.test(new Uint8Array(0))
exports.arrayKeys = exports.test([1])
