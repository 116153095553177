export default [
  {
    text: 'now',
    value: 0,
  },
  {
    text: '1m ago',
    value: 60,
  },
  {
    text: '5m ago',
    value: 300,
  },
  {
    text: '10m ago',
    value: 600,
  },
  {
    text: '30m ago',
    value: 1800,
  },
  {
    text: '1h ago',
    value: 3600,
  },
  {
    text: '3h ago',
    value: 10800,
  },
]
